/* eslint-disable camelcase */
import content from "./index";
import { common } from "./common";
import { errors } from "./errors";
import { library, library2 } from "./library";
import { login, login2 } from "./login";
import { mapContent, mapContent2, mapContent3, mapContent4, mapContent5, mapContent6, mapContent7, mapContent8 } from "./map";
import { people } from "./people";
import { permissions } from "./permissions";
import { projects } from "./projects";
import { runSheet } from "./run-sheet";
import { schedule } from "./schedule";
import { schedule2 } from "./schedule2";
import { schedule3 } from "./schedule3";
import { settings, settings2, settings3 } from "./settings";
import { table } from "./table";
import { timeRules } from "./time-rules";
import { subscriptionPlan, subscriptionPlan2, subscriptionPlan3, subscriptionPlan4 } from "./subscription-plan";
import { attributes } from "./attributes";
import { comments } from "./comments";
import { notifications } from "./notifications";

export const Content: ContentInterface = content;

export type Common = typeof common;

export type Errors = typeof errors;

export type Library = typeof library;

export type Library2 = typeof library2;

export type Login = typeof login;

export type Login2 = typeof login2;

export type MapContent = typeof mapContent;

export type MapContent2 = typeof mapContent2;

export type MapContent3 = typeof mapContent3;

export type MapContent4 = typeof mapContent4;

export type MapContent5 = typeof mapContent5;

export type MapContent6 = typeof mapContent6;

export type MapContent7 = typeof mapContent7;

export type MapContent8 = typeof mapContent8;

export type People = typeof people;

export type Permissions = typeof permissions;

export type Projects = typeof projects;

export type RunSheet = typeof runSheet;

export type Schedule = typeof schedule;

export type ScheduleTwo = typeof schedule2;

export type ScheduleThree = typeof schedule3;

export type Settings = typeof settings;

export type SettingsTwo = typeof settings2;

export type SettingsThree = typeof settings3;

export type Table = typeof table;

export type TimeRules = typeof timeRules;

export type SubscriptionPlan = typeof subscriptionPlan;

export type SubscriptionPlan2 = typeof subscriptionPlan2;

export type SubscriptionPlan3 = typeof subscriptionPlan3;

export type SubscriptionPlan4 = typeof subscriptionPlan4;

export type Attributes = typeof attributes;

export type Comments = typeof comments;

export type Notifications = typeof notifications;

export const TableContent = Content.table;

export interface ContentInterface {
    common: Common;
    library: Library;
    library2: Library2;
    map: MapContent;
    map2: MapContent2;
    map3: MapContent3;
    map4: MapContent4;
    map5: MapContent5;
    map6: MapContent6;
    map7: MapContent7;
    map8: MapContent8;
    settings: Settings;
    settings2: SettingsTwo;
    settings3: SettingsThree;
    errors: Errors;
    permissions: Permissions;
    projects: Projects;
    login: Login;
    login2: Login2;
    schedule: Schedule;
    schedule2: ScheduleTwo;
    schedule3: ScheduleThree;
    people: People;
    table: Table;
    runsheet: RunSheet;
    timerules: TimeRules;
    subscriptionplan: SubscriptionPlan;
    subscriptionplan2: SubscriptionPlan2;
    subscriptionplan3: SubscriptionPlan3;
    subscriptionplan4: SubscriptionPlan4;
    attributes: Attributes;
    comments: Comments;
    notifications: Notifications;
}
